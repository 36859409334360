import React from 'react'
import Layout from "../components/layout"
import {Link} from 'gatsby'

const success = () => {
    return (
        <Layout>
            <div className="text-center w-full font-customBody xl:w-screen xl:max-w-6xl">
            <h1 className="text-xl xl:text-4xl font-customHeader">Thank you for contacting us!</h1>
            <p>Your form submission was successful. </p>
            <p>We will reply in 1-2 business days.</p>
            <button className="bg-secondary font-customHeader hover:bg-gray-800 hover:text-gray-100 focus:outline-none focus:shadow-outline active:bg-teal-500 p-4 text-gray-900 text-base font-medium font-sans cursor-pointer shadow"><Link to="/">Back to Home</Link></button>
            </div>
           
        </Layout>

    )
}

export default success
